import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  width: 100%;
  margin-top: 4.8rem;
  padding: 1rem 1.5rem;
  @media (min-width: 1024px) {
    padding: 1rem 8rem;
  }
`;

export const TableContent = styled.div`
  width: 100%;
  & .truncate{
    width: 15rem;
    overflow: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const Content = styled.div`
  padding: 1rem;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  overflow: auto;
  background-color: ${({theme: {colors}})=> colors.background_white};
  @media (min-width: 1024px) {
    padding: 3rem;
  }
  & .table{
    min-width: 90rem;
  }
`