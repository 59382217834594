import { Cancel } from "@mui/icons-material";
import { Box, IconButton, LinearProgress, Typography } from "@mui/material";
import { Modal } from "components";
import { RecklessTraderController } from "controllers";
import { useEffect, useState } from "react";
import { ModalProps } from "..";

interface PdfViewerRecklessTraderReportProps extends ModalProps {
  pdfId?: string;
  onDownload?: () => void;
}

const PdfViewerRecklessTraderReport: React.FC<
  PdfViewerRecklessTraderReportProps
> = ({ pdfId, onDownload, ...props }: PdfViewerRecklessTraderReportProps) => {
  const [urlPdf, setUrlPdf] = useState<string>();
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    let url: string | undefined;
    if (pdfId) {
      setDownloading(true);
      RecklessTraderController.download(pdfId, "pdf")
        .then(async (urls: string[]) => {
          url = urls[0];
          setUrlPdf(url);
          onDownload?.();
        })
        .catch(() => setUrlPdf(undefined))
        .finally(() => setDownloading(false));
    } else setUrlPdf(undefined);

    return () => {
      if (url) window.URL.revokeObjectURL(url);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfId]);

  return (
    <Modal
      data-testid="pdf-viewer-mock"
      {...props}
      sx={{
        position: "relative",
        width: "60vw",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <IconButton
        size="small"
        onClick={props?.onClose}
        color="error"
        sx={{
          position: "absolute",
          top: "-5px",
          right: "-5px",
        }}
      >
        <Cancel />
      </IconButton>
      {downloading ? (
        <Box
          sx={{
            flex: 1,
            flexGrow: 1,
            height: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4" color="primary" sx={{ pl: 2 }}>
            Baixando arquivo...
          </Typography>
          <LinearProgress
            sx={{
              my: 2,
              width: "80%",
            }}
          />
          <Typography sx={{ pl: 2 }}>
            <em>Por favor aguarde!</em>
          </Typography>
        </Box>
      ) : (
        <object
          data={urlPdf}
          type="application/pdf"
          width="100%"
          height="100%"
          style={{
            borderRadius: "8px",
          }}
        >
          <p>
            Falha ao carregar o PDF... Você pode tentar visualizá-lo{" "}
            <a
              href={`${process.env.REACT_APP_API_BASE_URL}/recklessTrader/download/${pdfId}`}
            >
              clicando neste link
            </a>
            !
          </p>
        </object>
      )}
    </Modal>
  );
};

export default PdfViewerRecklessTraderReport;
