import styled from "styled-components";

export const BrackLine = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 1px;
  margin: 1rem 0 1rem 0;
  width: 100%;
  border-radius: 5px;
`;

export const SelectExternalFonts = styled.div`
  border: 1px solid
    ${({
      theme: {
        colors: { primary },
      },
    }) => primary};
  padding: 0 1rem 1rem 1rem;
  border-radius: 0.4rem;
  margin: 0 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  .items {
    align-self: center;
    width: 100%;
    height: 10rem;
    overflow: auto;
  }
  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .grid {
    display: grid;
    @media (min-width: 880px) {
      grid-template-columns: auto auto;
    }
  }
`;
