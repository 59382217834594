import { AppBar } from "components";
import { Container, Content, TableContent } from "./styles";
import {
  Grid,
  TextField,
  useTheme,
  Typography,
  Button,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Select,
  Zoom,
  MenuItem,
  ButtonGroup,
  Tooltip,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAlert, useApp, useAuth, useRecklessTrader } from "contexts";
import { IRecklessTraderReportInputModel } from "inputModels";
import { RecklessTraderController } from "controllers";
import { IRecklessTraderReportViewModel } from "viewModels";
import { Refresh, Search } from "@mui/icons-material";
import PdfViewerRecklessTraderReport from "components/Modal/PdfViewerRecklessTraderReport";
import { saveAs } from "file-saver";
import JSZip from "jszip";

interface RecklessTraderPdfProps {
  index: number;
  recklessTraderReport: IRecklessTraderReportViewModel;
  pdf: (id: string, option: "pdf" | "zip") => void;
  zip: (id: string, option: "pdf" | "zip") => void;
}

const RecklessTraderPdf = ({
  index,
  recklessTraderReport,
  pdf,
  zip,
}: RecklessTraderPdfProps) => {
  const [statusPdf, setStatusPdf] = useState(0);

  useEffect(() => {
    setStatusPdf(recklessTraderReport.pdf === undefined ? 0 : 3);
  }, [recklessTraderReport.pdf]);

  const generatePdf = () => {
    setStatusPdf(1);
    RecklessTraderController.GeneratePdf(recklessTraderReport.id)
      .then((url) => {
        recklessTraderReport.pdf = url;
        setStatusPdf(3);
      })
      .catch(() => setStatusPdf(2));
  };

  return (
    <TableRow data-testid='rowReport'>
      <TableCell>
        <Typography color="primary">{index}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{recklessTraderReport.recklessTrader.document}</Typography>
      </TableCell>
      <TableCell>
        <Tooltip
          title={
            <h1 style={{ fontSize: 15 }}>
              {recklessTraderReport.recklessTrader.name}
            </h1>
          }
          followCursor
          TransitionComponent={Zoom}
        >
          <Typography className="truncate">
            {recklessTraderReport.recklessTrader.name}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          title={
            <h1 style={{ fontSize: 15 }}>{recklessTraderReport.description}</h1>
          }
          followCursor
          TransitionComponent={Zoom}
        >
          <Typography className="truncate">
            {recklessTraderReport.description}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Typography>{recklessTraderReport.createdAt}</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {statusPdf === 0 ? (
            <Button
              size="small"
              color="info"
              variant="outlined"
              onClick={generatePdf}
            >
              Gerar PDF
            </Button>
          ) : statusPdf === 1 ? (
            <Button
              sx={{ pointerEvents: "none" }}
              size="small"
              color="warning"
              variant="outlined"
            >
              Gerando PDF
            </Button>
          ) : statusPdf === 2 ? (
            <Button
              title="Clique para tentar novamente"
              size="small"
              color="error"
              variant="outlined"
              onClick={generatePdf}
            >
              FALHA
              <Refresh sx={{ pl: 1 }} />
            </Button>
          ) : (
            <ButtonGroup>
              <Button
                size="small"
                color="error"
                variant="outlined"
                onClick={() => pdf(recklessTraderReport.id, "pdf")}
              >
                PDF
              </Button>
              <Button
                size="small"
                color="info"
                variant="outlined"
                onClick={() => zip(recklessTraderReport.id, "zip")}
              >
                ZIP
              </Button>
            </ButtonGroup>
          )}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

const RecklessTraderReport = () => {
  const theme = useTheme();
  const confirm = useAlert();
  const { user } = useAuth();
  const { setLoading } = useApp();
  const { recklessTraders, recklessTradersReport, setRecklessTradersReport } =
    useRecklessTrader();
  const [recklessTradersReportSelected, setRecklessTradersReportSelected] =
    useState<IRecklessTraderReportViewModel[]>([]);
  const [pdfId, setPdfId] = useState<string>();
  const [downloading, setDownloading] = useState<string>();

  const [defaultValues] = useState<IRecklessTraderReportInputModel>({
    recklessTraderId: "default",
    description: "",
  });

  useEffect(() => {
    setRecklessTradersReportSelected(recklessTradersReport);
  }, [recklessTradersReport]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
  });

  const today = () => {
    const date = new Date();
    let day: string | number = date.getDate();
    let month: string | number = date.getMonth() + 1;
    const year = date.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    return day + "/" + month + "/" + year;
  };

  const onSubmit = (recklessTraderReport: IRecklessTraderReportInputModel) => {
    RecklessTraderController.InsertReport(recklessTraderReport).then((id) => {
      setRecklessTradersReport([
        {
          id: id,
          createdAt: today(),
          CreatedBy: user ? user.name : "",
          description: recklessTraderReport.description,
          recklessTrader: recklessTraders.filter(
            (r) => r.id === recklessTraderReport.recklessTraderId
          )[0],
          pdf: undefined,
        },
        ...recklessTradersReport,
      ]);
      setRecklessTradersReportSelected([
        {
          id: id,
          createdAt: today(),
          CreatedBy: user ? user.name : "",
          description: recklessTraderReport.description,
          recklessTrader: recklessTraders.filter(
            (r) => r.id === recklessTraderReport.recklessTraderId
          )[0],
          pdf: undefined,
        },
        ...recklessTradersReport,
      ]);
      reset();
    });
  };

  const handleDownload = (id: string, option: "pdf" | "zip") => {
    if (downloading) return;
    setDownloading(id);

    switch (option) {
      case "pdf":
        RecklessTraderController.download(id, option)
          .then((urls: string[]) => {
            setLoading(true);
            const url = urls[0];
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${id}.${option}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            setLoading(false);
          })
          .catch(() => {
            confirm.show({
              type: "error",
              title: "Falha ao baixar",
              timeout: 5000,
              description: `Algum erro aconteceu ao baixar o arquivo ${option.toUpperCase()}.\nTente novamente...`,
              retry: {
                show: false,
              },
              cancel: {
                label: "Ok",
              },
            });
          })
          .finally(() => setDownloading(undefined));
        break;
      case "zip":
        RecklessTraderController.download(id, "zip")
          .then(async (urls: string[]) => {
            setLoading(true);
            const zip = new JSZip();
            for (const url of urls) {
              const response = await fetch(url);
              const blob = await response.blob();
              const fileName = url.split("/").pop()?.split("?")[0] ?? "file";
              zip.file(fileName, blob);
            }
            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, "download.zip");
              setLoading(false);
            });
          })
          .catch(async () => {
            await confirm.show({
              type: "error",
              title: "Falha ao baixar",
              timeout: 5000,
              description:
                "Algum erro aconteceu ao baixar o arquivo ZIP.\nTente novamente...",
              retry: {
                show: false,
              },
              cancel: {
                label: "Ok",
              },
            });
          })
          .finally(() => setDownloading(undefined));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <AppBar />
      <Container>
        <Content>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} padding="0 1rem">
              <Grid item xs={12}>
                <Typography component="p" variant="h4" color="primary">
                  Gerar Relatório
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name="recklessTraderId"
                  control={control}
                  rules={{
                    minLength: {
                      value: 9,
                      message: "Selecione sua empresa",
                    },
                  }}
                  render={({ field: { ...rest } }) => (
                    <Select
                      label="Empresa"
                      fullWidth
                      size="small"
                      error={!!errors.recklessTraderId?.message}
                      {...rest}
                    >
                      <MenuItem disabled value="default">
                        Selecione uma empresa
                      </MenuItem>
                      {recklessTraders.map((r) => (
                        <MenuItem key={r.id} value={r.id}>
                          {r.document} - {r.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item xs={8} sm={10} md={7} lg={8}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    maxLength: {
                      value: 100,
                      message: "A descrição pode ter no máximo 100 caracteres",
                    },
                  }}
                  render={({ field: { ...rest } }) => (
                    <TextField
                      size="small"
                      fullWidth
                      label="Descrição"
                      inputProps={{ maxLength: 100 }}
                      error={!!errors.description?.message}
                      helperText={errors.description?.message}
                      {...rest}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sm={2} md={1}>
                <Button type="submit" variant="contained">
                  Criar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Content>

        <Content>
          <Grid container spacing={2} padding="0 1rem" data-testid='gridReport'>
            <Grid item xs={12}>
              <Typography component="p" variant="h4" color="primary">
                Relatórios
              </Typography>
            </Grid>
            <Grid item xs={8} sm={5} md={3} lg={2}>
              <TextField
                placeholder="Pesquisar.."
                fullWidth
                onChange={({ target: { value } }) => {
                  if (value === "")
                    setRecklessTradersReportSelected(recklessTradersReport);
                  else
                    setRecklessTradersReportSelected(
                      recklessTradersReport.filter((r) =>
                        r.recklessTrader.name
                          .toLocaleLowerCase()
                          .includes(value.toLocaleLowerCase())
                      )
                    );
                }}
                InputProps={{
                  sx: {
                    pl: 1,
                  },
                  endAdornment: <Search />,
                }}
                variant="standard"
              />
            </Grid>
            <TableContent>
              <Table className="table" stickyHeader aria-label="listDossiers">
                <TableHead>
                  <TableRow
                    sx={{
                      th: {
                        backgroundColor: "white",
                        "& p": {
                          fontWeight: "bold",
                          color: theme.palette.primary.main,
                        },
                        "& div": {
                          display: "flex",
                          alignItems: "center",

                          "& button": {
                            ml: 1,
                          },
                        },
                      },
                    }}
                  >
                    <TableCell>
                      <Typography>#</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Documento</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="description">Empresa</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="truncate">Descrição</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Data de criação</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>Baixar Pdf</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recklessTradersReportSelected.length > 0 ? (
                    recklessTradersReportSelected.map((r, index) => (
                      <RecklessTraderPdf
                        recklessTraderReport={r}
                        index={index}
                        key={r.id}
                        pdf={handleDownload}
                        zip={handleDownload}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell>
                        <Typography>Nenhum Ponderador Encontrado...</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContent>
          </Grid>
        </Content>
      </Container>
      <PdfViewerRecklessTraderReport
        open={pdfId !== undefined}
        onClose={() => {
          setDownloading(undefined);
          setPdfId(undefined);
        }}
        pdfId={pdfId}
        onDownload={() => setDownloading(undefined)}
      />
    </>
  );
};

export default RecklessTraderReport;
